.right-menu-block {
  display: none;
  .right-menu{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 998;
    height: 100vh;
    box-shadow: 6px 0 20px #192a4659;
    transition: all .3s cubic-bezier(0,0,.2,1);
    white-space: nowrap;
    visibility: visible;
    width: 250px;
    background-color: #fff;
    transform: translate3d(calc(100% + 250px),0,0);

    &.colapsed{
      transform: translate3d(calc(100% - 250px),0,0);
    }

    .left-menu-list{
      position:relative;
      top: 50px;
      list-style: none;
      margin-left: 0;
      padding-left: 20px;
      .left-menu-item{
        display: flex;
        align-items: center;
        padding: 10px 0;
        font-weight: 600;
        .menu-item-icon{
          width: 40px;
          display: inline-block;
          &.createUserIcon{
            color: #975aff;
          }
          &.transferUserIcon{
            color: #40c057;
          }
        }
      }
    }
  }
}

#dropdown-flag{
  margin-top: 0;
}

.nav_link_new{
  margin: 0 5px !important;
}

@media (max-width: 960px){
  .createUserIconBlock, .transferUserIconBlock, .partnerUrlsIconBlock, .userBalanceIconBlock{
    display: none;
  }
  .right-menu-block{
    display: inline-block;
  }
  .header-navbar .navbar-container ul.navbar-nav li>a.nav-link {
    padding: 0.4rem !important;
  }

  .filter-card{
    width: calc(100vw - 30px);
    right: calc(-100vw + 35px) ;
  }

  .hiddenFilterCard{
    right: 0;
    form{
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 150px);
    }
  }
  .card{
    margin: 5px 0;
  }

  .row .filter_header, .wrapper .content-header {
    padding: 5px 10px;
    margin: 0.5rem 0;
    font-size: 1.3rem;
  }

  .card-body{
    padding: 10px;
  }
}

