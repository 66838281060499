@import '~dropzone/dist/min/dropzone.min.css';

.flex {
  display: flex;
}

.middle {
  align-items: center;
}

.space_btw {
  justify-content: space-between;
}

.tree_border {
  border-left: 7px solid;
  border-image-source: linear-gradient(#226cc5, #6cd975);
  border-image-slice: 1;
  margin-left: 20px !important;
}


.tree_symbol {
  display: inline-block;
  width: 10px;
  height: 5px;
  // border-radius: 50%;
  background: linear-gradient(#226cc5, #6cd975);
  position: relative;
  left: -5px;
}

.childe_tree {
  .tree_border {
    border: .5px solid;
    border-image-source: linear-gradient(#226cc5, #6cd975);
    border-image-slice: 1;
    border-left-width: 4px;
  }
}


.navbar_wrapper_new {

  background-repeat: repeat-x;
}

.nav_link_new {
  color: #212121 !important;
  margin: 0 10px;

  .ft-user-plus {
    color: #975AFF;
  }

  .fa-exchange {
    color: #40C057;
  }

  .icon-wallet {
    color: #2F8BE6;
  }

}

.text_header {
  color: #616161;
}

.form_control_new {

  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    right: 5px;
    top: 15px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #75787d transparent transparent transparent;
    transition: 0.5s;
  }

  &:hover {
    .form_control_list {
      display: block;
    }

    &::after {
      transform: rotate(180deg);
    }

  }
}


.form_control_list {
  list-style: none;
  position: absolute;
  background: #fff;
  box-shadow: -8px 8px 14px 0 rgba(25, 42, 70, 0.11);
  width: 100%;
  z-index: 1;
  left: 0;
  padding: 0;
  top: 35px;
  border: 1px solid #E0E0E0;
  border-top: none;
  border-radius: 0 0 5px 5px;
  display: none;

}

.form_control_row {
  padding: 7px 0;
  border-bottom: 1px solid #DCBDFF;
  width: 100%;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #975AFF;
  }
}


// .ic_wallet_new{
//  position: relative;

//     &:after{
//         content: "";
//         position: absolute;
//         width: 90px;
//         height: 90px;
//         background: linear-gradient(45deg,#226cc5, #6cd975);
//         background-repeat: repeat-x;
//         border-radius: 50%;
//         padding: 3px;
//         top: -5px;
//         left: 50%;
//         transform: translate(-50%, 0);
//     }
// }


.short-agent-wallet {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 33px;
  margin: 0 auto 20px;
  background: linear-gradient(45deg, rgba(34, 108, 197, 0.3), rgba(108, 217, 117, 0.3));

  i {
    font-weight: bold;
  }
}

.total_ic {
  color: #B583FF;
}


.balance_ic {
  color: #6CD975;
}


.credit_ic {
  color: #F9877C;
}


.dollar_ic {
  color: #60AFF0;
}


.mail_ic {
  color: #FAA750;
}


.agent_balance_txt {
  font-size: 16px;
  margin: 0;
}

.user_list {
  &:nth-child(odd) {
    background: rgba(213, 241, 253, 0.5);
  }
}

.player_btn {
  background-image: linear-gradient(45deg, rgba(176, 41, 64, 0.6), rgba(252, 193, 115, 0.8));
  background-repeat: repeat-x;
  color: #fff;
  border: none;
  border-radius: 3px;
  width: 36px;
  height: 36px;
}


.ngx-datatable {
  .player_btn {
    font-size: 16px !important;
  }
}

.filter_title {
  padding-bottom: 5px;
}

.filter_cont {
  padding-bottom: 0;
}

.row .filter_header {
  padding: 8px 10px;
}

.comittion_row {
  background: rgba(213, 241, 253, 0.7);

  &:nth-child(odd) {
    background: rgba(224, 251, 218, 0.7);
  }

}


.comittion_row_inp {
  padding: 10px 0;
  text-align: center;

  &:focus {
    text-align: left;
    border-bottom: 1px solid #975aff;
  }
}

.selected-email {
  background-color: #EFDEFF !important;
}

.list-group-item-action {

  &:hover, &:focus {
    background-color: rgba(239, 222, 255, 0.4);
  }


}


.list-group-item {

  padding: 12px;
}

.mail_head {
  width: 90px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mail_txt {
  font-size: 12px;
}

.mail_heading {
  margin-bottom: 5px;
}

.inner_msg {
  border: 1px solid #EFDEFF;
  border-radius: 5px !important;
}

.delete_msg_ic {
  margin-left: 4px !important;
}

.mail_inner_title {
  padding: 20px 0 10px;

}

.msg_cont {
  border: 1px solid transparent;
  margin-top: 20px;
  border-radius: 5px;
  background: #EAF0F9;
}


.PreviewImg_block {
  height: 150px;
  border: 1px solid #e5e5e5;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  img {
    width: 100%;
    max-height: -webkit-fill-available;
  }

  .closePreviewImg {
    position: absolute;
    right: -11px;
    top: -13px;
    cursor: pointer;
  }


}


.ft-upload-cloud {
  font-size: 77px;
}

.fieldset-legend {
  border: 1px solid #E0E0E0;
  padding: inherit;
  legend {
    width: initial;
    padding: 0 10px;
    font-size: 0.7rem;
  }
}




.hybrid-plan-tabs{
  display: flex;
  justify-content: center;
  div{
    font-weight: 600;
    width: 35%;
    text-align: center;
    padding: 0 20px;
    margin: 0px 30px;
    &.active {
      border-bottom: 3px solid blue;
    }
  }


}

.hybrid-plan-name{

  display: flex;
  justify-content: space-around;

  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}



.preview-frame {
  position: fixed;
  width: calc(100% - 30px);
  height: calc(100% - 70px);
  z-index: 9999;
  top: 65px;
  left: 15px;

  iframe {
    width: 100%;
    height: 100%;
  }

  .close-frame{
    font-size: 36px;
    position: absolute;
    right: -7px;
    top: -7px;
    z-index: 8;
    background: #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: cadetblue;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
}
