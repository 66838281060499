.paymentStatus {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 15px;

  &.paymentStatus_0{
    background-color: #D46010;
  }
  &.paymentStatus_1{
    background-color: #1efa4a;
  }
  &.paymentStatus_2{
    background-color: #D23B48;
  }
  &.paymentStatus_3{
    background-color: #7441DB;
  }
}

.withdrawal_padding_card {
  background-image: linear-gradient(45deg, #D46010, #FCC173);
}

.withdrawal_denied_card {
  background-image: linear-gradient(45deg, #D23B48, #F9877C);
}

.withdrawal_processed_card {
  background-image: linear-gradient(45deg, #7441DB, #C89CFF);
}
