/* You can add global styles to this file, and also import other style files */

.filter-card {
  /* position: fixed;
   width: 400px;
   height: 100vh;
   top: 0;
   right: 0;
   z-index: 999;
   margin: 0;*/
  right: -390px;
  margin: 0;
  height: calc(100% - 60px);
  top: 59px;
}
.filter-card .card-title {
  letter-spacing: 0.03rem;
  font-size: 1.1rem;
  margin-bottom: 0;

  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding: 10px !important;
}

.filter-card .card-header span {
  position: absolute;
  left: -23px;
  top: calc(30% - 50px);
}

.filter-card .card-header span a {
  background: #ffffff;
  color: #975aff;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-card .card-header span a i {
  font-size: 21px;
  font-weight: bold;
}

/*.main-panel {
  z-index: 4;
}*/

.hiddenFilterCard {
  right: 0;
}





.navbar{
  background-color: #fff;
}





.delete-media-img-icon {

  background: url("./assets/img/icons/Delete.png");
  background-size: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 9;
  right: -7px;
  top: -10px;
  cursor: pointer;
  opacity: 0.91;
  display: none;
}

.update-media-img-icon {
  background: url("./assets/img/icons/Edit.png");
  background-size: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 9;
  right: 22px;
  top: -10px;
  cursor: pointer;
  opacity: 0.91;
  display: none;
}

.generate-media-img-icon {
  background: url("./assets/img/icons/Generate_icon.png");
  background-size: cover;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 9;
  right: 51px;
  top: -10px;
  cursor: pointer;
  opacity: 0.91;
  display: none;
}
.banners-list:hover .delete-media-img-icon ,.banners-list:hover  .update-media-img-icon ,.banners-list:hover  .generate-media-img-icon{
  display: block;
}

.delete-media-img-icon:hover{
  color: #8300e4 !important;
  background: url("./assets/img/icons/Delete_hover.png");
  opacity: 1;
}
.update-media-img-icon:hover{
  color: #8300e4 !important;
  background: url("./assets/img/icons/Edit_hover.png");
  opacity: 1;
}
.generate-media-img-icon:hover{
  color: #8300e4 !important;
  background: url("./assets/img/icons/Generate_hover.png");
  opacity: 1;
}






tr.red,
tr.k-alt.red {
  background-color: #ffcaca;
}
tr.green,
tr.k-alt.green {
  background-color: #b2f699;
}


.modal{
  z-index: 1056;
}
td dl{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

}
td dl dt {
  text-align: end;
  padding-right: 10px;
}


td dl dt:after {
  content: '';
}


td dl dd {
  width: 100%;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 450px) {
  .sssm-no-padding{
    padding: 5px !important;
  }

}


@import "assets/css/right-menu.component";
