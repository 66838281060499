@import "../core/variables/variables";
@import "../core/variables/app-variables";
@import "../core/colors/palette-variables";

.ng-dropdown-panel .ng-dropdown-panel-items {
  .ng-option {
    img {
      margin-right: 0.7rem;
      &+b {
        vertical-align: middle;
      }
    }
    &.ng-option-marked {
      background-color: $primary !important;
      color: $white !important;
    }
  }
  .ng-option-selected {
    background-color: $primary !important;
    color: $white !important;
  }
}
.ng-select {
  .ng-select-container {
    background-color: transparent !important;
    border-color: $border-color;
    border-radius: $border-radius;
    color: $body-color;
  }
  .ng-dropdown-panel {
    border-color: $border-color;
    box-shadow: none;
    margin: 0;
  }
  &.ng-select-opened>.ng-select-container {
    border-color: $primary !important;
  }
  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: $primary;
    box-shadow: none;
  }
  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: $primary;
    color: $white;
    border-radius: $border-radius;
    .ng-value-icon {
      border: 1px solid map-get($primary-color, darken-1);
      border-right: 0 !important;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      padding: 1px 0 1px 5px;
      &:hover {
        background-color: inherit;
      }
    }
    .ng-value-label {
      border: 1px solid map-get($primary-color, darken-1);
      border-left: 0 !important;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

.ng-select.is-invalid .ng-select-container {
  border-color: #F55252;
}
html body.layout-dark {
  // for dark layout
  &:not(.layout-transparent) {
    .ng-select {
      .ng-select-container {
        border-color: $dl-border-color;
        color: $dl-text-color;
        .ng-input>input,
        .ng-input>input::placeholder {
          color: $dl-text-color;
        }
      }
    }
  }
  // for transparent layout
  &.layout-transparent {
    .ng-select {
      .ng-select-container {
        border-color: $tl-border-color;
        color: $tl-text-color;
        .ng-input>input,
        .ng-input>input::placeholder {
          color: $tl-text-color;
        }
      }
    }
  }
  // for dark and transparent layouts
  .ng-dropdown-panel {
    border-color: $dl-border-color;
    .ng-dropdown-panel-items .ng-option {
      background-color: $dl-bg-color;
      color: $dl-text-color;
      &.ng-option-disabled {
        color: $dl-disabled-color;
      }
    }
    .ng-optgroup.ng-option-disabled {
      background-color: $dl-bg-color;
      color: $dl-disabled-color;
    }
    .ng-dropdown-header,
    .ng-dropdown-footer {
      background-color: $bg-color-dark;
      color: $dl-disabled-color;
      border-color: $dl-border-color;
    }
  }
}
